/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useContext, useState } from 'react';
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { SocketManagerInstance } from "../../util/socketManager";
import { formatViewportChangedPayload } from '../../util/SocketMessages';

import styles from './draggablemap.module.scss'
import map from '../../assets/images/map.png';
import arrows from '../../assets/images/map_drag_arrows.png';
import TargetAudienceContext from '../../context/target-audience-context';
import TopicsContext from '../../context/topics-context';
import MapResetContext from '../../context/map-reset-context';
import { TargetAudience } from '../../util/constants';
import config from '../../content/wall-interactive-config.json';

gsap.registerPlugin(Draggable);

const { Consumer, Business } = config.hotspots;

const VIEWABLE_AREA = .15820313;
const ANIM_DURATION = 1.2;

const DraggableMap = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [hasDraggable, setHasDraggable] = useState(false);
  let halfway = (window.innerWidth - (window.innerWidth * VIEWABLE_AREA)) / 2;
  const [borderX, setBorderX] = useState(halfway);

  const [targetAudience] = useContext(TargetAudienceContext);
  const [mapReset, setMapReset] = useContext(MapResetContext);
  const setTopics = useContext(TopicsContext);
  const { animateCoachMarks, setAnimateCoachMarks, touchScreenPosition } = props;
  const borderRef = useRef(null);
  const mapRef = useRef(null);
  const targetRef = useRef(TargetAudience.CONSUMER);
  const viewportPercent = useRef(50);
  const tweenRef = useRef(null);
  const orderRef = useRef(0);

  // calculates the percent offset of the viewport slice
  // should injest the config based on map type
  // determine what topics are viewable at any given time
  // disptach topics back up so the appropriate top buttons
  // are displayed on the Home layout
  // setTopics(topics);
  const calculatePercent = (x, sendData = true) => {
    const WINDOW_WIDTH = window.innerWidth;
    const percent = Math.max(0, Math.min((x / (WINDOW_WIDTH - (WINDOW_WIDTH * VIEWABLE_AREA)) * 100), 100));
    const windowPercent = Math.max(0, Math.min((x / WINDOW_WIDTH * 100), 100))-1.5;
    viewportPercent.current = percent;

    if (sendData) {
      SocketManagerInstance.sendMessageWithData(formatViewportChangedPayload(percent, orderRef.current));
      orderRef.current += 1;
    }

    const currentDataSet = targetRef.current === TargetAudience.CONSUMER ? Consumer : Business;

    setTopics((prevTopics) => {
      let newTopics = [...prevTopics];

      // remove topics if they don't exist in the data set
      newTopics = newTopics.filter(item => {
        return currentDataSet.find(data => data.id === item.id);
      })

      for (let i = 0; i < currentDataSet.length; i++) {
        const item = currentDataSet[i];
        const exists = newTopics.find(topic => topic.id === item.id);
        const hotspotPercent = item.xPos * 100;
        if (hotspotPercent >= windowPercent && hotspotPercent <= (windowPercent + (VIEWABLE_AREA * 100))) {
          // add to array if it doesn't exist
          if (!exists) {
            newTopics.push({ label: item.label, id: item.id, selected: false });
          }
        }
        else {
          // remove if it does exist but not in the active viewport
          if (exists) {
            newTopics = newTopics.filter(topic => topic.id !== item.id);
          }
        }
      };
      return newTopics;
    });
  };

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    targetRef.current = targetAudience;
  }, [targetAudience]);

  useEffect(() => {
    const draggable = Draggable.get(borderRef.current);
    if (draggable) {
      gsap.set([borderRef.current], { x: (viewportPercent.current / 100) * (width - (width * VIEWABLE_AREA)) });
      draggable.update();  //needed for the correct calculate percentage
      setBorderX(draggable.x);
      calculatePercent(draggable.x);
    }
  }, [width, targetAudience]);

  useEffect(() => {
    if (touchScreenPosition > 0) {
      const draggable = Draggable.get(borderRef.current);
      if (draggable) {
        gsap.set([borderRef.current], { x: (touchScreenPosition / 100) * (width - (width * VIEWABLE_AREA)) });
        draggable.update();  //needed for the correct calculate percentage
        setBorderX(draggable.x);
        calculatePercent(draggable.x, false);
      }
    }
  }, [touchScreenPosition]);

  useEffect(() => {
    if (mapReset) {
      const draggable = Draggable.get(borderRef.current);
      gsap.to([borderRef.current], {
        duration: .5, x: halfway, ease: 'power4.inOut', onUpdate: () => {
          if (draggable) {
            draggable.update();
            setBorderX(draggable.x);
            calculatePercent(draggable.x, false);
          }
        }
      });
      setMapReset(false);
    }
  }, [mapReset]);

  useEffect(() => {
    if (animateCoachMarks) {
      const draggable = Draggable.get(borderRef.current);
      if (draggable) {
        draggable.disable();
      }
      let progress = 0;
      if (tweenRef.current != null) {
        progress = tweenRef.current.progress();
        tweenRef.current.kill();
      }
      const tl = gsap.timeline({
        onComplete: () => {
          if (draggable) {
            draggable.enable();
            setAnimateCoachMarks(false);
          }
        },
        repeat: 0,
        ease: 'power4.inOut',
        onUpdate: () => {
          if (draggable) {
            draggable.update();
            setBorderX(draggable.x);
          }
        }
      });
      tl.to(borderRef.current, { duration: ANIM_DURATION / 2, x: halfway - (width * .15) });
      tl.to(borderRef.current, { duration: ANIM_DURATION, x: halfway + (width * .15) });
      tl.to(borderRef.current, { duration: ANIM_DURATION / 2, x: halfway });
      if (progress !== 0) {
        tl.progress(progress);
      }
      tl.play();

      tweenRef.current = tl;
    }
  }, [animateCoachMarks, width]);

  useEffect(() => {
    if (!hasDraggable) {
      //draggable is an array
      Draggable.create(borderRef.current, {
        type: "x",
        bounds: mapRef.current,
        dragClickables: true,
        onDrag: function () {
          setBorderX(this.x);
          calculatePercent(this.x);
        }
      });
      // center initially
      gsap.set([borderRef.current], { x: halfway });
      setBorderX(halfway);
      calculatePercent(halfway);
      setHasDraggable(true);
    }
  }, [hasDraggable]);

  return (
    <div className={styles.draggableMap} >
      <div ref={mapRef} id="mapContainer" className={styles.mapContainer}>
        <img src={map} className={styles.mapBackground} alt="map" />
        <div style={{ width: `calc(${(borderX / width) * 100}% + 2px)` }} className={styles.mapOverlay}></div>
        <div style={{ left: `calc(${(borderX / width) * 100 + (VIEWABLE_AREA * 100)}% - 2px)`, width: `calc(${100 - ((borderX / width) * 100 + (VIEWABLE_AREA * 100))}% + 2px)` }} className={styles.mapOverlay}></div>
      </div>
      <div className={styles.mapGradient} ></div>
      <div ref={borderRef} className={styles.pinkBorder}>
        <img src={arrows} className={styles.arrows} alt="" />
      </div>
    </div>
  );
}

export default DraggableMap;