import { gsap } from "gsap";
import { useEffect, useRef, useContext } from 'react';
import Nav from '../Nav';
import styles from './header.module.scss'
import CoachMarksContext from '../../context/coachmarks-context';

export default function Header(props) {
  const [showCoachMarks] = useContext(CoachMarksContext);
  const headerRef = useRef(null);

  useEffect(() => {
    if (!showCoachMarks) {
      gsap.to(headerRef.current, { duration: .5, autoAlpha: 1 });
    }
  }, [showCoachMarks]);

  return (
    <header ref={headerRef} className={`${styles.root} ${props.className}`}>
      <Nav
        resetMap={props.resetMap}
        showOutro={props.showOutro} />
    </header>
  );
}

