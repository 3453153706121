const WS_ENDPOINT = process.env.REACT_APP_WS_ENDPOINT;

const kActionRequestDetails = "request-details";
const kActionMessageClient = "message-client";
const kMessageReconnect = "lc-reconnect";


class SocketManager {
    //would like to make thse private, but it appears private fields are "experimental" //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes/Private_class_fields

    constructor() {
        console.log("built socket manager instance");

        //NOTE - not sure why this works vs assigning normal member vars, but it does the trick to make them writable
        this.ws = {
            value: null,
            writable: true
        }
        this.sessionId = {
            value: false,
            writable: true
        };
        this.sessionIdUriDecoded = {
            value: "",
            writable: true
        }
        this.disconnectListeners = {
            value: [],
            writable: true
        }
        this.errorListeners = {
            value: [],
            writable: true
        }
        this.messageListeners = {
            value: new Map(),
            writable: true
        };

        //key = message string, value = [] of listeners
        this.bSessionInitialized = { value: false, writable: true };
        this.socketDetails = { value: null, writable: true };
        this.addSocketMessageListener(kActionRequestDetails, this.socketSessionDetailsReceived.bind(this));
        this.addSocketMessageListener(kMessageReconnect, this.lcReconnected.bind(this));
    }

    getExperienceId() {
         if (this.socketDetails.value !== null && this.socketDetails.value.expId !== undefined)
         {
             return this.socketDetails.value.expId;
         }
         else {
             return null;
         }
    }

    lcReconnected() {
        console.log("LC Reconnected. requesting details");
        this.requestDetails();
    }

    connect(sessionId) {
        console.log(`SocketManager.connect - initialized: ${this.bSessionInitialized.value}`);

        if (!this.bSessionInitialized.value) {
            this.sessionId.value = encodeURIComponent(sessionId);
            this.sessionIdUriDecoded.value = decodeURIComponent(sessionId);

            let url = WS_ENDPOINT + '/?id=' + this.sessionId.value;
            //console.log(`SocketManager - attempting connection to: ${url}, decoded: ${this.sessionIdUriDecoded.value}`);

            this.ws.value = new WebSocket(url);
            this.ws.value.onopen = this.socketOpen.bind(this);
            this.ws.value.onmessage = this.socketMessageReceived.bind(this);
            this.ws.value.onerror = this.socketError.bind(this);
            this.ws.value.onclose = this.socketDisconnect.bind(this)
            this.bSessionInitialized.value = true;

        } else {
            console.log("SocketManager::initialize - WARNING - socket sesssion already initialized. Attempt to reinitalize this session failed");
            this.socketError({});
        }
    }

    //the listener is  a ref to the object instance's function. doing this way since we don't have interfaces in ES6 
    addSocketMessageListener(messageType, listenerFunc) {
        if (this.messageListeners.value.has(messageType)) {
            this.messageListeners.value.get(messageType).push(listenerFunc);
        } else {
            this.messageListeners.value.set(messageType, [listenerFunc]);
        }
    }
    addSocketErrorListener(listenerFunc) {
        this.errorListeners.value.push(listenerFunc);
    }

    removeSocketMessageListener(messageType, listenerFunc) {
        this.messageListeners.value.has(messageType) ? this.messageListeners.value.get(messageType).filter(obj => { return (obj !== listenerFunc); }) : console.log("attempt to remove socket listener for nonexistent message type: " + messageType);
    }

    addSocketDisconnectListener(listener) {
        this.disconnectListeners.value.push(listener);
    }

    removeSocketDisconnectListener(listener) {
        this.disconnectListeners.value = this.disconnectListeners.value.filter(obj => { return obj === listener; });
    }

    removeSocketErrorListener(listener) {
        this.disconnectListeners.value = this.disconnectListeners.value.filter(obj => { return obj === listener; });
    }

    socketOpen() {
        console.log("WS connection successful");
        console.log("attempting request details from socket endpt");
        this.requestDetails();
    }

    socketError(err) {
        this.errorListeners.value.forEach(item => { item(err); });
    }

    socketDisconnect(dis) {
        this.bSessionInitialized.value = false;
        console.log(`SocketManager.socketDisconnet - initialized: ${this.bSessionInitialized.value}`);
        this.disconnectListeners.value.forEach(listener => { listener(dis); });
    }

    socketMessageReceived(msg) {
        var payload = JSON.parse(msg.data);

        console.log("payload", payload);
        //listeners are mapped to "action" param in data payload of message
        //we expect data field of primary payload to have an action and a correpsonding data entry
        //eg. incoming msg looks like 
        /*
            {"action":"message",
             "data":{"
                 "action":"story-selected",
                 "data":{
                     "story-id":1
                 }
             }
            }
        */

        var action = payload.action;

        //simplify mapping slightly... not ideal, but prevents having to have sub-mappings of actions to data.actions
        // if (action === kActionMessageClient) {
        //     action = JSON.parse(payload.data).action;
        // }

        console.log("action: " + action);
        if (this.messageListeners.value.has(action)) {
            this.messageListeners.value.get(action).forEach(listener => {
                if (listener) {
                    if (payload.action === kActionRequestDetails) {
                        listener(payload);
                    }
                    else {
                        console.log("parsing data: " + payload.data);
                        listener(payload.data);
                    }
                }
            });
        }
    }

    //added as a message listener for kActionRequestDetails
    socketSessionDetailsReceived(payload) {
        console.log("SocketManager received session details from server" + payload);
        console.log(payload);
        this.socketDetails.value = {
            conId: payload.conId,
            uuid: payload.uuId,
            locId: payload.locId,
            sessionId: this.sessionId.value,
            expId: payload.expId,
            created: payload.created,
            controllerMicrositeUrl: payload.controllerMicrositeUrl,
            controllerWebSocketUrl: payload.controllerWebSocketUrl,
            controllerTargetUrl: payload.controllerTargetUrl
        }
    }

    //init session
    requestDetails() {
        if (!this.bSessionInitialized.value) return;
        this.ws.value.send(
            JSON.stringify({
                "action": "request-details",
                "sessionId": this.sessionIdUriDecoded.value
            }));
    }

    disconnect() {
        this.bSessionInitialized.value = false;
        console.log("User closing socket session manually");
        if (this.ws && this.ws.value) this.ws.value.close();        
    }

    sendMessageWithData(dataV) {
        if (this.socketDetails.value === null) {
            console.log("SocketManager::sendMessageWithData - attempt to send socket data before socket connection complete");
            return;
        }
        const payload = {
            action: "message",
            sessionId: this.sessionIdUriDecoded.value,
            locId: this.socketDetails.value.locId,
            expId: this.socketDetails.value.expId,
            conId: this.socketDetails.value.conId,
            controllerTargetUrl: this.socketDetails.value.controllerTargetUrl,
            data: dataV
        };

        //console.log("emitting message with payload" + JSON.stringify(payload));
        this.ws.value.send(JSON.stringify(payload));
    }
}

const SocketManagerInstance = new SocketManager();
Object.freeze(SocketManagerInstance);

export {
    SocketManagerInstance,
    kActionRequestDetails,
    kActionMessageClient
}