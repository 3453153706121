import styles from './naviconbutton.module.scss'
import { NavIconButtonType } from '../../util/constants';
import btnClose from '../../assets/images/btn_close.svg';
import btnHome from '../../assets/images/center_icon.png';

const NavIconButton = (props) => {
  const { id, navClickHandler, clickHandler } = props;

  const buildIcons = () => {
    switch (props.type) {
      case NavIconButtonType.HOME:
        return (<img src={btnHome} alt="home" style={{width:"35px"}} />);
      case NavIconButtonType.CLOSE:
        return (<img src={btnClose} alt="close" />);
      default:
        return null;
    }
  }

  return (
    <button
      onClick={() => {
        navClickHandler(id);
        clickHandler();
      }}
      className={styles.navIconButton}>
      {buildIcons()}
    </button >
  );
}

export default NavIconButton;

