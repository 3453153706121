import React from 'react';
import { useEffect, useRef } from "react";
import { gsap } from "gsap";

import styles from './introview.module.scss'
import logo from '../../assets/images/t_mobile_logo.svg';

const IntroView = (props) => {
    const VISIBLE_DURATION = 3500;
    const modalRef = useRef(null);
    const containerRef = useRef(null);
    const logoRef = useRef(null);
    const copyRef = useRef(null);

    const { hideIntro } = props;

    useEffect(() => {
        const tl = gsap.timeline({ duration: .8, repeat: 0, ease: 'power4.inOut' });
        tl.set(logoRef.current, { scale: .1 });
        tl.to(logoRef.current, { scale: 1, autoAlpha: 1 });
        tl.to(containerRef.current, { duration: 1.4, y: "-12vh", ease: 'expo.out' }, "+=.2");
        tl.to(copyRef.current, { autoAlpha: 1 }, "<");

        const timer = setTimeout(() => {
            // Do animations, then close down
            gsap.to(modalRef.current, { duration: .5, autoAlpha: 0, ease: "none", onComplete: hideIntro });
        }, VISIBLE_DURATION);

        return function cleanup() {
            clearTimeout(timer)
        };
    }, [hideIntro]);

    return (
        <div ref={modalRef} className={styles.modal}>
            <div ref={containerRef} className={styles.innerContainer}>
                <img className={styles.logo} ref={logoRef} src={logo} alt="T-Mobile logo" />
                <p ref={copyRef} className={styles.introCopy}>Use this controller to explore our 5G&nbsp;world.</p>
            </div>
        </div>
    );
}
export default IntroView;