/* eslint-disable no-unused-vars */
import React from 'react';
import { useCallback, useEffect, useReducer, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import NoSleep from 'nosleep.js';

import LandingView from '../LandingView';
import DetailView from '../DetailView';
import FiveGWorldView from '../FiveGWorldView';

import ModalStatusView  from '../../components/ModalStatusView';
import LandscapeWarning from "../../components/LandscapeWarning";
import IntroView from "../../components/IntroView";
import OutroView from "../../components/OutroView";
import Footer from "../../components/Footer";
import { InitGA, Event } from "../../components/Tracking";

import TargetAudienceContext from "../../context/target-audience-context";
import CoachMarksContext from "../../context/coachmarks-context";
import MapResetContext from "../../context/map-reset-context";
import SelectedTopicContext from "../../context/selected-topic-context";
import AnimationContext from "../../context/animation-context";
import StateReducerContext from "../../context/state-reducer-context";

import { SocketManagerInstance, kActionRequestDetails, kActionMessageClient } from "../../util/socketManager";
import { formatEndSessionPayload } from '../../util/SocketMessages';
import Constants from '../../util/constants';
import { topics } from '../../content/topics.json';
import { pageVisibilityApi } from '../../util/util';
import { ActionType, TargetAudience, Orientation, MessageAction } from "../../util/constants";

import styles from './app.module.scss';

const { hidden, visibilityChange } = pageVisibilityApi();
const RECONNECT_MAX = 3;

let sessionIdParam = null;
let expId = null;
let locId = null;
let buttonDisconnect = false;

const initialState = {
  connectionStatus: Constants.ConnectionStatus.NONE,
  connectionStatusView: Constants.ConnectionStatusView.INIT,
  errorType: Constants.ErrorType.NONE,
  introAlreadyShown: false,
  showIntro: false,
  showCoachMarks: false,
  showOutro: false,
  isVisible: true,
  numReconnectionAttempts: 0,
  orientation: null,
  touchScreenPosition: -1,
  experienceId: null,
  locationId: null,
  sessionId: null,
  didError: false
};

const logState = (state, msg) => {
  console.log(`${msg}
    sessionId: ${state.sessionId}
    experienceId: ${state.experienceId}
    locationId: ${state.locationId}
    connectionStatus: ${state.connectionStatus}
    connectionStatusView: ${state.connectionStatusView}
    introAlreadyShown: ${state.introAlreadyShown}
    showIntro: ${state.showIntro}
    showCoachMarks: ${state.showCoachMarks}
    showOutro: ${state.showOutro}
    isVisible: ${state.isVisible}
    numReconnectionAttempts: ${state.numReconnectionAttempts}
    orientation: ${state.orientation}
    didError: ${state.didError}
    touchScreenPosition: ${state.touchScreenPosition}`);
}

const reducer = (state, action) => {

  //logState(state, "reducer");

  switch (action.type) {
    case ActionType.CONNECTION_STATUS:
      switch (action.payload) {
        case Constants.ConnectionStatus.CONNECTING:
          return {
            ...state,
            connectionStatus: Constants.ConnectionStatus.CONNECTING,
            connectionStatusView: Constants.ConnectionStatusView.LOADING,
          };
        case Constants.ConnectionStatus.CONNECTED:
          // let intro = true;
          // if (state.introAlreadyShown) {
          //   intro = false;
          // }
          
          return {
            ...state,
            connectionStatus: Constants.ConnectionStatus.CONNECTED,
            connectionStatusView: Constants.ConnectionStatusView.NONE,
            didError: false,
            experienceId: expId,
            locationId: locId,
            sessionId: sessionIdParam
            //introAlreadyShown: true,
            //showIntro: intro,
          };
        case Constants.ConnectionStatus.ERROR:
          if (!state.didError)
          {
            Event("engage","error message viewed","Oops, there was a connection issue",window.location.pathname,state.sessionId,state.locationId,state.experienceId,"error","error");
          }
          return {
            ...state,
            didError: true,
            connectionStatus: Constants.ConnectionStatus.ERROR,
            connectionStatusView: Constants.ConnectionStatusView.ERROR,
          };
        case Constants.ConnectionStatus.DISCONNECTED:
          if (state.isVisible && !state.showOutro && state.numReconnectionAttempts < RECONNECT_MAX) {
            console.log(`trying to reconnect, numReconnectionAttempts is ${state.numReconnectionAttempts}`);
            return {
              ...state,
              connectionStatus: Constants.ConnectionStatus.CONNECTING,
              connectionStatusView: Constants.ConnectionStatusView.LOADING,
              numReconnectionAttempts: state.numReconnectionAttempts + 1
            };
          }
          else if (state.numReconnectionAttempts >= RECONNECT_MAX) {
            console.log(`max reconnect attempts reached, error!`);
            return {
              ...state,
              connectionStatus: Constants.ConnectionStatus.DISCONNECTED,
              connectionStatusView: Constants.ConnectionStatusView.ERROR,
            };
          }
          return state;
        case Constants.ConnectionStatus.GOODBYE:
          return {
            ...state,
            connectionStatus: Constants.ConnectionStatus.GOODBYE,
            connectionStatusView: Constants.ConnectionStatusView.GOODBYE,
          };
        default:
          return state;
      }
    case ActionType.CONNECTION_STATUS_VIEW:
      return { ...state, connectionStatusView: action.payload };
    case ActionType.SHOW_INTRO:
      return {
        ...state,
        showCoachMarks: true,
        showIntro: action.payload
      };
    case ActionType.SHOW_OUTRO:
      return { ...state, showOutro: action.payload };
    case ActionType.TARGET_AUDIENCE:
      return { ...state, targetAudience: action.payload };
    case ActionType.ORIENTATION:
      return {
        ...state,
        orientation: action.payload
      }
    case ActionType.VIEWPORT_CHANGED:
      return {
        ...state,
        touchScreenPosition: action.payload
      }
    case ActionType.IS_VISIBLE:
      const isVisible = action.payload;
      if (isVisible) {
        if (state.connectionStatus === Constants.ConnectionStatus.DISCONNECTED && !state.showOutro) {
          return {
            ...state,
            isVisible: isVisible,
            connectionStatus: Constants.ConnectionStatus.CONNECTING,
            connectionStatusView: Constants.ConnectionStatusView.LOADING
          };
        }
        else {
          return state;
        }
      }
      else {
        return { ...state, isVisible: isVisible };
      }
    default:
      return state;
  }
};

const App = (props) => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const [targetAudience, setTargetAudience] = useState(TargetAudience.CONSUMER);
  const [showCoachMarks, setShowCoachMarks] = useState(true);
  const [mapReset, setMapReset] = useState(false);  
  const [animateCoachMarks, setAnimateCoachMarks] = useState(false);
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(null);

  const history = useHistory();
  

  const navigate = useCallback((id) => {
    
    const topic = topics.filter(t => t.id.toString() === id)[0];
    const path = (topic) ? topic.path : '/';

    // Special case for 5GWorld - need to start modal animation before page is loaded
    if (id === Constants.View.FIVE_G_WORLD.toString()) {
      dispatch({
        type: ActionType.SHOW_INTRO,
        payload: true,
      });
    }    
    
    // Navigate
    history.push(path);  

  }, [history]);

  useEffect(() => {

    // let noSleep = new NoSleep();
    console.log(`App.useEffect [CONNECTION_STATUS, orientation] - status: ${state.connectionStatus}, orientation: ${state.orientation}`);

    // if (typeof DeviceOrientationEvent['requestPermission'] === 'function') {
    //   DeviceOrientationEvent['requestPermission']()
    //   .then(permissionState => {
    //     if (permissionState === 'granted') {
    //       window.addEventListener('deviceorientation',function enableNoSleepOrientation(){
    //         window.removeEventListener('deviceorientation',enableNoSleepOrientation,false);
    //         noSleep.enable();
    //       },false);
    //     }
    //   });  
    // }
    // else{
    //   window.addEventListener('deviceorientation',function enableNoSleepOrientation(){
    //     window.removeEventListener('deviceorientation',enableNoSleepOrientation,false);
    //     noSleep.enable();
    //   },false);
    //   window.addEventListener('MozOrientation',function enableNoSleepMozOrientation(){
    //     window.removeEventListener('MozOrientation',enableNoSleepMozOrientation,false);
    //     noSleep.enable();
    //   },false);
    // }
    
    // if (typeof DeviceMotionEvent['requestPermission'] === 'function') {
    //   DeviceMotionEvent['requestPermission']()
    //   .then(permissionState => {
    //     if (permissionState === 'granted') {
    //       window.addEventListener('devicemotion',function enableNoSleepMotion(){
    //         window.removeEventListener('devicemotion',enableNoSleepMotion,false);
    //         noSleep.enable();
    //       },false);
    //     }
    //   });  
    // }
    // else{
    //   window.addEventListener('devicemotion',function enableNoSleepMotion(){
    //     window.removeEventListener('devicemotion',enableNoSleepMotion,false);
    //     noSleep.enable();
    //   },false);
    // }

    // need a state where session Id doesn't exist?
    if (state.connectionStatus === Constants.ConnectionStatus.CONNECTING
      && state.orientation === Orientation.PORTRAIT) {

      try {
        SocketManagerInstance.connect(sessionIdParam);
      }
      catch (error) {
        console.log(`ERROR - Problem initializing SocketManager: ${error}`);
        dispatch({
          type: ActionType.CONNECTION_STATUS,
          payload: Constants.ConnectionStatus.ERROR
        });
      }
    }

  }, [state.connectionStatus, state.orientation]);

  useEffect(() => {

    const visibilityChangeHandler = () => {
      dispatch({
        type: ActionType.IS_VISIBLE,
        payload: !document[hidden]
      });

      if (document[hidden]) {
        showOutro();
      }
    };

    function orientationChangeHandler(m) {
      dispatch({
        type: ActionType.ORIENTATION,
        payload: m.matches ? Orientation.PORTRAIT : Orientation.LANDSCAPE
      });
    }

    var mql = window.matchMedia("(orientation: portrait)");

    dispatch({
      type: ActionType.ORIENTATION,
      payload: mql.matches ? Orientation.PORTRAIT : Orientation.LANDSCAPE
    });

    try {
      mql.addEventListener('change', orientationChangeHandler);
    } catch (e1) {
      try {
        // solution for iOS 13 exception
        mql.addListener(orientationChangeHandler);
      } catch (e2) {
        console.log(e2);
      }
    }

    try {
      // request-details
      SocketManagerInstance.addSocketMessageListener(
        kActionRequestDetails,
        (payload) => {
          console.log(`App.requestDetailsSuccess - ${JSON.stringify(payload)}`);
          expId = payload.expId;
          locId = payload.locId;
          dispatch({
            type: ActionType.CONNECTION_STATUS,
            payload: Constants.ConnectionStatus.CONNECTED
          });
        }
      );

      // message-client
      SocketManagerInstance.addSocketMessageListener(
        kActionMessageClient,
        (payload) => {
          console.log(`App.Message - ${JSON.stringify(payload)}`);
          const payloadSplit = payload.split(":");
          switch (payloadSplit[0]) {
            case MessageAction.BUTTON_CLICKED:
              if (payloadSplit[1].trim() === "c") {
                setTargetAudience(TargetAudience.CONSUMER);
              }
              else if (payloadSplit[1].trim() === "b") {
                setTargetAudience(TargetAudience.BUSINESS);
              }
              else {
                setSelectedTopicIndex(payloadSplit[1].trim());
              }
              break;
            case MessageAction.VIEWPORT_CHANGED:
              dispatch({
                type: ActionType.VIEWPORT_CHANGED,
                payload: payloadSplit[1]
              });
              break;
            case MessageAction.END_SESSION:
              showOutro();
              break;
            case MessageAction.NAVIGATE:
              navigate(payloadSplit[1].trim());
              break;
            case MessageAction.TOPIC_BUTTON_CLICKED:
              // For now, do nothing. May want to set button state to selected.
              break;
            default:
              console.log("Message received, but data action doesn't match");
              break;
          }
        }
      );

      // Socket error event
      SocketManagerInstance.addSocketErrorListener((event) => {
        console.log(`App.addSocketErrorListener - ${JSON.stringify(event)}`);
        dispatch({
          type: ActionType.CONNECTION_STATUS,
          payload: Constants.ConnectionStatus.ERROR
        });
      });

      // Socket disconnect event
      SocketManagerInstance.addSocketDisconnectListener((event) => {
        console.log(`App.addSocketDisconnectListener - ${JSON.stringify(event)}`);
        dispatch({
          type: ActionType.CONNECTION_STATUS,
          payload: Constants.ConnectionStatus.DISCONNECTED
        });
      });

      // Subscribe to page visibility events
      document.addEventListener(visibilityChange, visibilityChangeHandler);

      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      window.addEventListener('resize', resizeHandler);

      // Parse query params for id (sessionId)
      const params = new URLSearchParams(window.location.search);
      sessionIdParam = params.get("id");

      console.log(`App.useEffect [] - sessionId: ${sessionIdParam}`);

      // If we have session id, attempt socket connection, else display error.
      if (sessionIdParam) {
        dispatch({
          type: ActionType.CONNECTION_STATUS,
          payload: Constants.ConnectionStatus.CONNECTING
        });
      } 
      else {
        dispatch({
          type: ActionType.CONNECTION_STATUS,
          payload: Constants.ConnectionStatus.ERROR
        });
      }
      
      // Init analytics
      InitGA();
    }
    catch (error) {
      console.log(`ERROR - Problem initializing SocketManager: ${error}`);
    }

    return function cleanup() {
      document.removeEventListener(visibilityChange, visibilityChangeHandler);
      window.removeEventListener('resize', resizeHandler);
      try {
        mql.removeEventListener('change', orientationChangeHandler);
      } catch (e1) {
        try {
          // solution for iOS 13 exception
          mql.removeListener(orientationChangeHandler);
        } catch (e2) {
          console.log(e2);
        }
      }
    };
  }, [navigate]);

  const setConnectionStatusViewCallback = useCallback((state, e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch({
      type: ActionType.CONNECTION_STATUS_VIEW,
      payload: state
    });
  }, []);

  const showOutro = () => {
    dispatch({
      type: ActionType.SHOW_OUTRO,
      payload: true,
    });
  }

  const disconnectButtonClickHandler = () => {
    Event("engage","disconnect",window.location.pathname,window.location.pathname,state.sessionId,state.locationId,state.experienceId,"disconnect","post control");
    showOutro();
  }

  const endSessionAndDisconnect = useCallback(() => {
    SocketManagerInstance.sendMessageWithData(formatEndSessionPayload(sessionIdParam));
    SocketManagerInstance.disconnect();
    sessionIdParam = null;
  }, []);

  const removeHome = useCallback(() => {
    dispatch({
      type: ActionType.CONNECTION_STATUS,
      payload: Constants.ConnectionStatus.GOODBYE
    });
  }, []);

  const hideIntro = useCallback(() => {
    //console.log(`NewApp.hideIntro`);
    setAnimateCoachMarks(true);
    dispatch({
      type: ActionType.SHOW_INTRO,
      payload: false,
    });
  }, []);

  const resizeHandler = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }  

  return (
    <div className={styles.appRoot}>

      <TargetAudienceContext.Provider value={[targetAudience, setTargetAudience]}>
      <CoachMarksContext.Provider value={[showCoachMarks, setShowCoachMarks]}>
      <MapResetContext.Provider value={[mapReset, setMapReset]}>
      <SelectedTopicContext.Provider value={[selectedTopicIndex, setSelectedTopicIndex]}>
      <AnimationContext.Provider value={[showOutro, animateCoachMarks, setAnimateCoachMarks]}>
      <StateReducerContext.Provider value={[state, dispatch]}>

      {/* Routed content */}
      <div className={styles.contentSection}>
        <Switch>
          <Route exact path="/" render={(props) => <LandingView {...props} /> } />
          <Route path="/detail/:id" render={(props) => <DetailView {...props} /> } />
          <Route path="/5g" render={(props) => <FiveGWorldView {...props} /> } />
        </Switch>
      </div>

      {/* Footer */}
      <div className={styles.footerSection} >
        <Footer disconnect={disconnectButtonClickHandler}/>
      </div>

      {/* Modal landscape warning */}
      {state.orientation === Orientation.LANDSCAPE ? <LandscapeWarning /> : null}  

      {/* Modal status view */}
      {state.connectionStatusView !== Constants.ConnectionStatusView.NONE ? <ModalStatusView /> : null}

      {/* Modal intro animation */}          
      {state.showIntro && <IntroView hideIntro={hideIntro} />}   
      {state.showOutro && <OutroView removeHome={removeHome} endSession={endSessionAndDisconnect} sessionId={sessionIdParam}/>} 

      </StateReducerContext.Provider>
      </AnimationContext.Provider>
      </SelectedTopicContext.Provider>
      </MapResetContext.Provider>
      </CoachMarksContext.Provider>
      </TargetAudienceContext.Provider>
    </div>
  );
};

export default App;