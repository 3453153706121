import React from 'react';
import { useLocation } from 'react-router-dom';
import LandingTopicButton from '../../components/LandingTopicButton';
import { SocketManagerInstance } from "../../util/socketManager";
import { formatNavigatePayload } from '../../util/SocketMessages';
import { View } from "../../util/constants";
import styles from './footer.module.scss';
import backIcon from '../../assets/images/back_icon.png';

const Footer = (props) => {  

  const { disconnect } = props;
  const location = useLocation();
  const renderBackButton = location.pathname !== "/";

  const itemClickHandler = (id) => {
    //console.log(`Footer.itemClickHandler - item id: ${id}`);
    SocketManagerInstance.sendMessageWithData(formatNavigatePayload(id));
  }

  return (
    <div className={styles.footerRoot}>
      { renderBackButton && 
        <LandingTopicButton
          topic={{name:"Select Topic"}}
          buttonClass={styles.backButton}
          icon={backIcon}
          iconClass={styles.backIcon}
          path={`/`}
          clickHandler={() => itemClickHandler(View.HUB)}/>
      }  
      <LandingTopicButton
        topic={{name:"Disconnect"}} 
        buttonClass={styles.disconnectButton}       
        clickHandler={disconnect}/> 
    </div>
  )
};

export default Footer;
