import NavIconButton from '../NavIconButton';
import NavToggleGroup from '../NavToggleGroup';
import styles from './nav.module.scss';
import { NavIconButtonType } from '../../util/constants';
import { SocketManagerInstance } from "../../util/socketManager";
import { formatButtonClickPayload } from '../../util/SocketMessages';

const Nav = (props) => {
  const navClickHandler = (id) => {
    if (id !== "") {
      SocketManagerInstance.sendMessageWithData(formatButtonClickPayload(id));
    }
  }

  return (
    <nav className={`${styles.nav}`}>
      <NavIconButton
        id={"h"}
        type={NavIconButtonType.HOME}
        navClickHandler={navClickHandler}
        clickHandler={props.resetMap} />
      <NavToggleGroup navClickHandler={navClickHandler} />
      {/* <NavIconButton
        id={""}
        type={NavIconButtonType.CLOSE}
        navClickHandler={navClickHandler}
        clickHandler={props.showOutro} /> */}
    </nav>
  );
}

export default Nav;

