import ReactGA from 'react-ga';

const GA_ID = process.env.REACT_APP_GA_ID;
let gaClientId;

export const InitGA = () => {  

  if (!GA_ID) {
    console.log(`Tracking.InitGA - missing Google Analytics identifier`);
    return;
  }

  console.log(`Tracking.InitGA - ${GA_ID}`);    

  ReactGA.initialize(GA_ID, {
    debug: true,
    titleCase: false,
    gaOptions: {}
  });
}

export const PageView = (sessionId, locationId, experienceId, siteSection) => {  

  if (!sessionId) {
    console.log(`Tracking.PageView - missing sessionId`);
    return;
  }
  
  console.log(`Tracking.PageView - sessionId: ${sessionId}, experienceId: ${experienceId}, locationId: ${locationId}, siteSection: ${siteSection}, window.location: ${window.location}, window.location.pathname: ${window.location.pathname}, window.location.search: ${window.location.search}, title: ${document.title}`);
  
  // if (sessionId)
  gaClientId = ReactGA.ga().getAll()[0].get('clientId');

    var pagePath = window.location.pathname;
    var newPagePath = pagePath.split("?")[0];
    
  // Set custom dimensions
  ReactGA.set({
    // tid: GA_ID, 
    // cid: gaClientId,
    page: newPagePath,
    dt: document.title,
    dimension1: gaClientId,
    dimension3: 'microsite-controller-techlab',
    dimension20: locationId,
    dimension27: siteSection,
    dimension30: experienceId,
    dimension33: "mobile controller",
    dimension37: sessionId
  });

  // Page view
  ReactGA.pageview(window.location.pathname + window.location.search); 
}

export const Event = (category, action, label, pagePath, sessionId, locationId, experienceId,  cd8, cd27) => {

  const event = {
    category: category,
    action: action,
    label: label
  };

  if (pagePath == null)
  {
    pagePath = "/"
  }
  else {
    pagePath = pagePath.split("?")[0];
  }

  // if (cd27 === "error")
  //   console.error("error")
  // if (sessionId)
  gaClientId = ReactGA.ga().getAll()[0].get('clientId');
    
  // Set custom dimensions
  ReactGA.set({ 
    // tid: GA_ID, 
    // cid: gaClientId,
    page: pagePath,
    dt: document.title,
    dimension1: gaClientId,
    dimension3: 'microsite-controller-techlab',
    dimension8: cd8,
    dimension20: locationId,
    dimension27: cd27,
    dimension30: experienceId,
    dimension37: null,
    dimension33: "mobile controller"
  });

  console.log(`Tracking.Event - ${JSON.stringify(event)} - sessionId: ${sessionId}, experienceId: ${experienceId}, locationId: ${locationId}`);

  ReactGA.event(event);
};