/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect, useRef, useContext } from 'react';
import Header from "../../components/Header/index";
import FiveGWorldHome from "../../components/FiveGWorldHome/index";
import { PageView, Event } from "../../components/Tracking";
import { TargetAudience } from "../../util/constants";
import styles from './fivegworldview.module.scss';

import MapResetContext from "../../context/map-reset-context";
import SelectedTopicContext from "../../context/selected-topic-context";
import AnimationContext from "../../context/animation-context";
import StateReducerContext from "../../context/state-reducer-context";
import TargetAudienceContext from '../../context/target-audience-context';

const FiveGWorldView = (props) => {

  const [mapReset, setMapReset] = useContext(MapResetContext);
  const [selectedTopicIndex, setSelectedTopicIndex] = useContext(SelectedTopicContext);
  const [showOutro, animateCoachMarks, setAnimateCoachMarks] = useContext(AnimationContext);
  const [state, dispatch] = useContext(StateReducerContext);
  const [targetAudience, setTargetAudience] = useContext(TargetAudienceContext);

  useEffect(() => {
    setMapReset(true);
    setTargetAudience(TargetAudience.CONSUMER);
  }, [setMapReset, setTargetAudience]);

  useEffect(() => {
    PageView(state.sessionId, state.locationId,state.experienceId,"5g world");
  }, [state.sessionId, state.locationId,state.experienceId]);

  // Below code causes "flicker" when navigating to 5g world
  // Instead of starting intro animation when page is loaded, intro animation is started on landing view button click
  // useEffect(() => {
  //   //console.log(`FiveGWorldView.useEffect [dispatch]`);  style={{ visibility: }} state.showIntro
  //   dispatch({
  //     type: ActionType.SHOW_INTRO,
  //     payload: true,
  //   });
  // }, [dispatch]);

  return (
    <div className={styles.fiveGViewRoot}>
      <Header
        resetMap={() => {
          Event("engage","precision icon click",window.location.pathname,window.location.pathname,state.sessionId, state.locationId,state.experienceId,null,"5g world")
          setMapReset(true);
        }}
        showOutro={showOutro}
        className={styles.headerSection}/>
      <FiveGWorldHome 
        className={styles.contentSection}
        selectedTopicIndex={selectedTopicIndex}
        setSelectedTopicIndex={setSelectedTopicIndex}
        touchScreenPosition={state.touchScreenPosition}
        animateCoachMarks={animateCoachMarks}
        setAnimateCoachMarks={setAnimateCoachMarks}/>
    </div>   
  );
};

export default FiveGWorldView;