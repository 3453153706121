/* eslint-disable no-unused-vars */
import { useState, useContext, useEffect } from 'react';
import NoSleep from 'nosleep.js';
import LandingTopicButton from '../../components/LandingTopicButton';
import { SocketManagerInstance } from "../../util/socketManager";
import StateReducerContext from "../../context/state-reducer-context";
import { formatTopicButtonClickPayload } from '../../util/SocketMessages';
import { PageView } from "../../components/Tracking";
import styles from './detailview.module.scss';
import { topics } from '../../content/topics.json';

const DetailView = (props) => {

  const [viewed, setViewed] = useState({});
  const [state, dispatch] = useContext(StateReducerContext);
  let noSleep = new NoSleep();

  const itemClickHandler = (id) => {
    noSleep.disable();
    setViewed(oldViewed => ({ ...oldViewed, [id]: true }));
    SocketManagerInstance.sendMessageWithData(formatTopicButtonClickPayload(id));
    if (selectedTopic.name.toLowerCase() === "4g connected living")
    noSleep.enable();
  }

  const selectedTopic = topics.find(x => x.id.toString() === props.match.params.id);

  useEffect(() => {
    PageView(state.sessionId, state.locationId, state.experienceId, selectedTopic.name);
  }, [state.sessionId, state.locationId,state.experienceId,selectedTopic.name]);

  return (
    <div className={styles.detailViewRoot}>
      <h1>{selectedTopic.name}</h1>
      <div>
        {selectedTopic.items.sort((a, b) => { return a.order - b.order}).map((item, index) => (
          <LandingTopicButton
            key={index} 
            topic={item} 
            style={{textTransform:'none'}}
            viewed={viewed[item.id]}
            clickHandler={() => itemClickHandler(item.id)}/>
        ))}
      </div>
    </div>
  );
}

export default DetailView;