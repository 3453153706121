import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import styles from './coachmarks.module.scss';
import coachMarksImage from '../../assets/images/coachmarks.svg';

const CoachMarks = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const imgRef = useRef(null);
    const tweenRef = useRef(null);
    const ANIM_DURATION = 1.2;
    const { toggleCoachMarks, animateCoachMarks } = props;

    useEffect(() => {
        const updateDimensions = () => {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        if (animateCoachMarks) {
            let progress = 0;
            if (tweenRef.current != null) {
                progress = tweenRef.current.progress();
                tweenRef.current.kill();
            }
            const tl = gsap.timeline({
                onComplete: toggleCoachMarks,
                repeat: 0,
                ease: 'power4.inOut'
            });
            tl.to(imgRef.current, { duration: ANIM_DURATION / 2, x: '-15vw' });
            tl.to(imgRef.current, { duration: ANIM_DURATION, x: '15vw' });
            tl.to(imgRef.current, { duration: ANIM_DURATION / 2, x: 0 });
            if (progress !== 0) {
                tl.progress(progress);
            }
            tl.play();

            tweenRef.current = tl;
        }
    }, [toggleCoachMarks, animateCoachMarks, width]);

    return (
        <div className={styles.coachMarksContainer}>
            <img ref={imgRef} className={styles.coachMarks} src={coachMarksImage} alt='coachmarks' />
        </div>
    );
}
export default CoachMarks;