import React from 'react';
import { useContext, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { Event, PageView } from "../../components/Tracking";
import StateReducerContext from "../../context/state-reducer-context";
import styles from './outroview.module.scss'
import svgFacebook from '../../assets/images/facebook.svg';
import svgTwitter from '../../assets/images/twitter.svg';
import svgLinkedIn from '../../assets/images/linkedin.svg';
import logo from '../../assets/images/t_mobile_logo.svg';

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

const socialLinks = [
    {
        text: 'Facebook',
        icon: svgFacebook,
        url: process.env.REACT_APP_FACEBOOK_URL
    },
    {
        text: 'Twitter',
        icon: svgTwitter,
        url: process.env.REACT_APP_TWITTER_URL
    },
    {
        text: 'LinkedIn',
        icon: svgLinkedIn,
        url: process.env.REACT_APP_LINKEDIN_URL
    }
];

const OutroView = (props) => {
    const [state] = useContext(StateReducerContext);
    const modalRef = useRef(null);
    const { removeHome, endSession, sessionId } = props;

    // dispatch

    useEffect(() => {
        PageView(state.sessionId, state.locationId, state.experienceId, "thank you");
        gsap.to(modalRef.current, {
            duration: .5, autoAlpha: 1, ease: "none", onComplete: () => {
                removeHome();
            }
        });

        endSession();
    }, [removeHome, endSession,state.sessionId, state.locationId, state.experienceId]);

    const takeASurveyClickHandler = (e) => {
        e.preventDefault();
        // console.error("take a survey click")
        Event("engage","take a survey link click",window.location.pathname,window.location.pathname,state.sessionId,state.locationId,state.experienceId,"disconnect","post control");
        window.open(e.target.href, '_blank');
    }

    const socialClickHandler = (e,socialName) => {
        e.preventDefault();
        // console.error("social click")
        Event("engage","social share link click",socialName,window.location.pathname,state.sessionId,state.locationId,state.experienceId,"disconnect","post control");
        window.open(e.target.href, '_blank');
    }

    // this was in the previous version
    const takeItWithYouClickHandler = (e) => {
        e.preventDefault();
        Event("engage","click to 5G world",window.location.pathname,window.location.pathname,state.sessionId,state.locationId,state.experienceId,"disconnect","post control")
        window.location.href = REDIRECT_URL;
    };

    return (
        <div ref={modalRef} className={`${styles.outroModal}`}>
            <div className={styles.innerContainer}>
                <img className={styles.logo} src={logo} alt="T-Mobile logo" />
                <div className={styles.thankYouCopy}>Thank you for learning about our 5g</div>  
                {/* survey link below - will have to fiddle with styling to make sure it works on smallest form factor*/}
                <div className={styles.surveyCta}>To make this experience even better</div>
                <a href={`https://www.surveymonkey.com/r/TMOTLP1?session=${sessionId}`} onClick={(e) => takeASurveyClickHandler(e)} className={styles.surveyLink} target="_blank" rel="noreferrer">take a survey</a>
                <p className={styles.shareCopy}>Share 5g world</p>
                <div className={styles.socialLinks}>
                  {socialLinks.map((socialLink, index) => (
                    <a
                      key={index}
                      href={socialLink.url}
                      onClick={(e) => socialClickHandler(e,socialLink.text)}
                      target="_blank"
                      rel="noreferrer">
                      <img
                        className={styles.socialLink}
                        src={socialLink.icon}
                        alt={socialLink.text} />
                    </a>
                  ))}
                </div>
                <button onClick={(e) => takeItWithYouClickHandler(e)} className={styles.button}>Take 5g world with you</button>
            </div>
        </div>
    );
}
export default OutroView;