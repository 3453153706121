/* eslint-disable no-unused-vars */
import React from 'react';
import { useContext } from 'react';
import { Event } from "../../components/Tracking";
import StateReducerContext from "../../context/state-reducer-context";
import styles from './modalstatusview.module.scss';
import Constants from '../../util/constants';
import logo from '../../assets/images/t_mobile_logo.svg';

const ModalStatusView = (props) => {

  const [state, dispatch] = useContext(StateReducerContext);

  const getMessageLine1 = () => {
    let msg;
    switch (state.connectionStatusView) {
      case Constants.ConnectionStatusView.NONE:
      case Constants.ConnectionStatusView.INIT:
      case Constants.ConnectionStatusView.LOADING:
        return null;
      case Constants.ConnectionStatusView.ERROR:
        if (!(msg === <div className={styles.message}>Oops, there was a connection issue</div>))
        {
          msg = <div className={styles.message}>Oops, there was a connection issue</div>;
          // Event("engage","error message viewed","Oops, there was a connection issue",window.location.pathname,state.sessionId,state.locationId,state.experienceId,"error","error");
        }
        break;
      default:
        return null;
    }
    return msg;
  }

  const getMessageLine2 = () => {
    let msg;
    switch (state.connectionStatusView) {
      case Constants.ConnectionStatusView.NONE:
        return null;
      case Constants.ConnectionStatusView.INIT:
        msg = <div className={styles.message}>Connecting...</div>;
        break;
      case Constants.ConnectionStatusView.LOADING:
        msg = <div className={styles.message}>Connecting...</div>;
        break;
      case Constants.ConnectionStatusView.ERROR:
        msg = <div className={styles.message}>Please scan QR code again</div>;
        break;
      default:
        return null;
    }
    return msg;
  }

  return (
    <div className={`${styles.modalRoot}`}>
      <div className={styles.topSection}>        
        {state.connectionStatusView === Constants.ConnectionStatusView.ERROR ? <img className={styles.logo} src={logo} alt="T-Mobile logo" /> : null}
        {getMessageLine1()}
      </div>
      <div className={styles.middleSection}>
        {getMessageLine2()}
      </div>
      <div className={styles.bottomSection}></div>
    </div>
  );
}

export default React.memo(ModalStatusView);