import { useContext } from 'react';
import styles from './navtogglebutton.module.scss'
import TargetAudienceContext from '../../context/target-audience-context';

const NavToggleButton = (props) => {
  const [targetAudience, setTargetAudience] = useContext(TargetAudienceContext);
  const { navClickHandler } = props;

  let className = styles.navToggleButton;
  if (props.label === targetAudience) {
    className += " " + styles.selected;
  }

  return (
    <button
      onClick={(e) => {
        if (targetAudience !== props.label) {
          setTargetAudience(props.label);
          navClickHandler(props.id);
        }
      }}
      className={className}>
      {props.label.toUpperCase()}
    </button>
  );
}

export default NavToggleButton;

