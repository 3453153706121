const ConnectionStatus = {
  NONE: "none",
  CONNECTING: "connecting",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  ERROR: "error",
  GOODBYE: "goodbye"
}

// These are messages to socket backend
const MessageAction = {
  REQUEST_DETAILS: "request-details",
  MESSAGE_CLIENT: "message-client",
  BUTTON_CLICKED: "button-clicked",
  VIEWPORT_CHANGED: "viewport-changed",
  END_SESSION: "end-session",
  NAVIGATE: "navigate",
  TOPIC_BUTTON_CLICKED: "topic-button-clicked"
}

const ErrorType = {
  NONE: 0,
  MISSING_SESSION_ID: 1,
  SOCKET_ERROR: 2
}

const View = {
  "HUB": 0,
  "FIVE_G_WORLD": 1,
  "FIVE_G_SPEED": 2,
  "FIVE_G_COVERAGE": 3,
  "CONNECTED_LIVING": 4
}

const ConnectionStatusView = {
  INIT: "init",
  NONE: "none",
  LOADING: "loading",
  ERROR: "error",
  GOODBYE: "goodbye"
}

const ActionType = {
  CONNECTION_STATUS: "connectionStatus",
  CONNECTION_DETAILS: "connectionDetails",
  CONNECTION_STATUS_VIEW: "connectionStatusView",
  SHOW_INTRO: "showIntro",
  SHOW_OUTRO: "showOutro",
  IS_VISIBLE: "isVisible",
  TARGET_AUDIENCE: "targetAudience",
  ORIENTATION: "orientation",
  VIEWPORT_CHANGED: "viewportChanged"
}

const TargetAudience = {
  CONSUMER: "Consumer",
  BUSINESS: "Business"
}

const Device = {
  MOBILE: "mobile",
  COMPUTER: "computer"
}

const Orientation = {
  PORTRAIT: "portrait",
  LANDSCAPE: "landscape"
}

const NavIconButtonType = {
  HOME: "home",
  CLOSE: "close"
}

module.exports = {
  ConnectionStatus: ConnectionStatus,
  MessageAction: MessageAction,
  ConnectionStatusView: ConnectionStatusView,
  ActionType: ActionType,
  TargetAudience: TargetAudience,
  Device: Device,
  Orientation: Orientation,
  NavIconButtonType: NavIconButtonType,
  ErrorType: ErrorType,
  View: View
};