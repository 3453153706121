/* eslint-disable no-unused-vars */
import { useContext, useEffect } from 'react';
import NoSleep from 'nosleep.js';
import LandingTopicButton from '../../components/LandingTopicButton';
import { ActionType, View } from "../../util/constants";
import { SocketManagerInstance } from "../../util/socketManager";
import { formatNavigatePayload } from '../../util/SocketMessages';
import StateReducerContext from "../../context/state-reducer-context";
import { PageView } from "../../components/Tracking";
import styles from './landingview.module.scss';
import { topics } from '../../content/topics.json';

const LandingView = (props) => {

  const [state, dispatch] = useContext(StateReducerContext);
  let noSleep = new NoSleep();
  useEffect(() => {
    PageView(state.sessionId, state.locationId, state.experienceId, "hub");
  }, [state.sessionId, state.locationId, state.experienceId]);

  const topicClickHandler = (id) => {

    noSleep.enable();
    // Working around an issue where 5g world view flickers
    // Need to start intro animation before 5G World (id = 1) components are rendered
    if (id === View.FIVE_G_WORLD) {
      dispatch({
        type: ActionType.SHOW_INTRO,
        payload: true,
      });
    }

    // Socket message - navigate
    SocketManagerInstance.sendMessageWithData(formatNavigatePayload(id));
  }

  return (
    <div className={styles.landingViewRoot}>
      <div className={styles.landingViewContainer}>
        <h1>Select a Topic</h1>
        <div>
          {topics.sort((a, b) => { return a.order - b.order}).map((topic, index) => (
            <LandingTopicButton
              key={index} 
              topic={topic}   
              path={topic.path}           
              clickHandler={() => topicClickHandler(topic.id)}/>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LandingView;
