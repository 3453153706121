/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import styles from './fivegworldhome.module.scss'
import DraggableMap from '../DraggableMap';
import TopicButton from '../TopicButton';
import CoachMarks from '../CoachMarks';
import TopicsContext from '../../context/topics-context';
import CoachMarksContext from '../../context/coachmarks-context';
import { SocketManagerInstance } from "../../util/socketManager";
import { formatButtonClickPayload } from '../../util/SocketMessages';

const FiveGWorldHome = (props) => {
  const [showCoachMarks, setShowCoachMarks] = useContext(CoachMarksContext);
  const [topics, setTopics] = useState([]);
  const [viewed, setViewed] = useState({});
  const {
    animateCoachMarks,
    setAnimateCoachMarks,
    touchScreenPosition,
    selectedTopicIndex,
    setSelectedTopicIndex
  } = props;

  const processTopics = (id) => {
    const processTopics = [];
    for (let i = 0; i < topics.length; i++) {
      const copy = { ...topics[i], selected: false };
      if (topics[i].id === id) {
        copy.selected = true;
      }
      processTopics.push(copy);
    }
    setTopics(processTopics);
  }

  const topicClickHandler = (id) => {
    console.log(`selected topic id is: ${id}`);
    setSelectedTopicIndex(id);
    setViewed(oldViewed => ({ ...oldViewed, [id]: true }));

    SocketManagerInstance.sendMessageWithData(formatButtonClickPayload(id));
  }

  useEffect(() => {
    setShowCoachMarks(true);
  }, [setShowCoachMarks]);

  useEffect(() => {
    //console.log(`Home.useEffect [selectedTopicIndex] - selectedTopicIndex: ${selectedTopicIndex}`);   
    if (selectedTopicIndex !== null) processTopics(selectedTopicIndex);
  }, [selectedTopicIndex]);

  return (
    <TopicsContext.Provider value={setTopics}>
      <div className={`${styles.homeRoot} ${props.className}`}>
        <div className={styles.mapSection}>
          <DraggableMap
            touchScreenPosition={touchScreenPosition}
            animateCoachMarks={animateCoachMarks}
            setAnimateCoachMarks={setAnimateCoachMarks} />
        </div>  
        <div className={styles.contentSection}>
          {showCoachMarks 
            ?
            <CoachMarks animateCoachMarks={animateCoachMarks} toggleCoachMarks={setShowCoachMarks} /> 
            :
            <div className={styles.contentRoot}>
              <div className={styles.instructionSection}>
                <div className={styles.instructionsHeadline}>
                  See how 5G enables your world.
                </div>
                <div className={styles.instructions}>
                  Tap the topic you're interested in.
                </div>
             </div>
              <div className={styles.topicSection}>
                <div className={styles.topicList}>
                  {topics.map((topic, index) => (
                    <TopicButton key={index} topic={topic} clickHandler={topicClickHandler} viewed={viewed[topic.id]} />
                  ))}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </TopicsContext.Provider>
  );



  // return (
  //   <TopicsContext.Provider value={setTopics}>
  //     <div className={`${styles.homeContainer} ${props.className}`}>      
  //       <DraggableMap
  //         touchScreenPosition={touchScreenPosition}
  //         animateCoachMarks={animateCoachMarks}
  //         setAnimateCoachMarks={setAnimateCoachMarks} />
        // {showCoachMarks ?
        //   <CoachMarks animateCoachMarks={animateCoachMarks} toggleCoachMarks={setShowCoachMarks} /> :
        //   <div className={styles.homeTopcisContainer}>
            // <div className={styles.instructionsHeadline}>
            //   See how 5G enables your world.
            // </div>
            // <div className={styles.instructions}>
            //   Tap the topic you're interested in.
            // </div>
        //     <div className={styles.topicButtonContainer}>
              // {topics.map((topic, index) => (
              //   <TopicButton key={index} topic={topic} clickHandler={topicClickHandler} viewed={viewed[topic.id]} />
              // ))}
        //     </div>
        //   </div>}      
  //     </div>
  //   </TopicsContext.Provider>
  // );
}

export default FiveGWorldHome;