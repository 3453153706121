import { MessageAction } from "./constants";

const formatViewportChangedPayload = (percent, order) => {
  return JSON.stringify({
    [MessageAction.VIEWPORT_CHANGED]: percent,
    "order": order
  });
}

const formatButtonClickPayload = (id) => {
  return JSON.stringify({
    [MessageAction.BUTTON_CLICKED]: id
  });
}

const formatEndSessionPayload = (sessionId = "") => {
  // if (sessionId === "") {
  //     return "end-session";
  // }
  return JSON.stringify({
    [MessageAction.END_SESSION]: sessionId
  });
}

const formatNavigatePayload = (id) => {
  return JSON.stringify({
    [MessageAction.NAVIGATE]: id
  });
}

const formatTopicButtonClickPayload = (id) => {
  return JSON.stringify({
    [MessageAction.TOPIC_BUTTON_CLICKED]: id
  });
}

export {
    formatViewportChangedPayload,
    formatButtonClickPayload,
    formatEndSessionPayload,
    formatNavigatePayload,
    formatTopicButtonClickPayload
}