import styles from './landscapewarning.module.scss';

const LandscapeWarning = () => {
    return (
        <div className={styles.landscapeWarning}>
            <div className={styles.content}>
                This experience is best viewed in portrait. Please rotate your device to portrait mode.
            </div>
        </div>
    )
}
export default LandscapeWarning;