import React from 'react';
import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import styles from './topicbutton.module.scss';

const TopicButton = (props) => {
    const topicRef = useRef(null);
    const { topic } = props;

    useEffect(() => {
        const tween = gsap.to(topicRef.current, { duration: .3, autoAlpha: 1 });
        return function cleanup() {
            // clean this up if it's removed during the animation
            tween.kill();
        };
    }, []);

    let className = styles.topicButton;
    if (props.viewed) {
        className += " " + styles.viewed;
    }

    return (
        <button ref={topicRef} className={className} onClick={() => props.clickHandler(topic.id)}>
            {topic.label}
        </button>
    );
}
export default TopicButton;