import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import styles from './landingtopicbutton.module.scss';

const LandingTopicButton = (props) => {

    const { topic, viewed, path, buttonClass, icon, iconClass, clickHandler } = props;
    
    const topicRef = useRef(null);
    
    useEffect(() => {

      //console.log(`LandingTopicButton.useEffect`);
      
      // Button animations
      const tween = gsap.to(topicRef.current, { duration: .3, autoAlpha: 1 });

      // Clean-up
      return function cleanup() {
          tween.kill();
      };
    }, []);

    // Button content can be text or icon
    let content = (icon) ? <img src={icon} className={iconClass} alt="select a different topic" /> : topic.name;

    // Update style if button has been clicked
    let className = `${styles.landingTopicButton} ${buttonClass}`;
    if (viewed) className += " " + styles.viewed;    

    return (
      <Link
        to={{
          pathname: path,          
          state: { fromLanding: true }
        }}>
        <button 
          ref={topicRef} 
          className={className}
          onClick={clickHandler}
          style={props.style}>
          {content}
        </button>
      </Link>
    );
}

export default LandingTopicButton;