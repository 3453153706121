import { useEffect, useRef, useContext } from "react";
import NavToggleButton from '../NavToggleButton'
import styles from './navtogglegroup.module.scss'
import { TargetAudience } from "../../util/constants";
import { gsap } from "gsap";
import TargetAudienceContext from '../../context/target-audience-context';

const NavToggleGroup = (props) => {
  const [targetAudience] = useContext(TargetAudienceContext);
  const selectorRef = useRef(null);
  const { navClickHandler } = props;

  useEffect(() => {
    const gotoX = targetAudience === TargetAudience.CONSUMER ? '0' : '50%';
    gsap.to(selectorRef.current, { duration: .4, left: gotoX, ease: 'expo.inOut' });

    return function cleanup() {
    };
  }, [targetAudience]);

  return (
    <div className={styles.navToggleGroup}>
      <div ref={selectorRef} className={styles.roundedSelector}></div>
      <NavToggleButton
        id="c"
        navClickHandler={navClickHandler}
        label={TargetAudience.CONSUMER} />
      <NavToggleButton
        id="b"
        navClickHandler={navClickHandler}
        label={TargetAudience.BUSINESS} />
    </div>
  );
}

export default NavToggleGroup;